import React,{useState} from 'react';
import { navigate } from 'gatsby';
import { Form, Field } from 'react-final-form';
import axios from 'axios';

export function ContactWebform({ page_data }) {

    return (
        <Form
            validate={values => {
                const errors = {}
                if (!values.activemedia_auth) {
                errors.activemedia_auth = 'Required *'
                }
                if (!values.name) {
                    errors.name = 'Required *'
                }
                if (!values.phone) {
                    errors.phone = 'Required *'
                }
                if (!values.email) {
                    errors.email = 'Required *'
                }
                return errors
            }}
            onSubmit={handleSubmit}
            render={({ handleSubmit, form, submitting, pristine }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <div className="contact-form__container" id="contact-form__container">
                        <div className="contact-form__container--box" id="contact-form">
                            <div className='form-group form-group-name'>
                                <Field name='name'>
                                    {({ input, meta }) => (
                                        <>
                                            <label htmlFor='name'>Name *</label>
                                            <input {...input} type='text'/>
                                            {meta.error && meta.touched && (
                                                <span className='form-error-message'>{meta.error}</span>
                                            )}
                                        </>
                                    )}
                                </Field>
                            </div>
                            <div className='form-group form-group-phone'>
                                <Field name='phone'>
                                    {({ input, meta }) => (
                                        <>
                                            <label htmlFor='phone'>Phone *</label>
                                            <input {...input} type='text'/>
                                            {meta.error && meta.touched && (
                                                <span className='form-error-message'>{meta.error}</span>
                                            )}
                                        </>
                                    )}
                                </Field>
                            </div>
                            <div className='form-group form-group-email'>
                                <Field name='email'>
                                    {({ input, meta }) => (
                                        <>
                                            <label htmlFor='email'>Email *</label>
                                            <input {...input} type='email'/>
                                            {meta.error && meta.touched && (
                                                <span className='form-error-message'>{meta.error}</span>
                                            )}
                                        </>
                                    )}
                                </Field>
                            </div>
                            <div className='form-group form-group-skype'>
                                <Field name='skype'>
                                    {({ input, meta }) => (
                                        <>
                                            <label htmlFor='skype'>Skype</label>
                                            <input {...input} type='text'/>
                                            {meta.error && meta.touched && (
                                                <span className='form-error-message'>{meta.error}</span>
                                            )}
                                        </>
                                    )}
                                </Field>
                            </div>
                            <div className='form-group form-group-company'>
                                <Field name='company'>
                                    {({ input, meta }) => (
                                        <>
                                            <label htmlFor='company'>Company</label>
                                            <input {...input} type='text'/>
                                            {meta.error && meta.touched && (
                                                <span className='form-error-message'>{meta.error}</span>
                                            )}
                                        </>
                                    )}
                                </Field>
                            </div>
                            <div className='form-group form-group-message'>
                                <label htmlFor='message'>Message</label>
                                <Field name="message" component="textarea" />
                            </div>
                            <div className='form-group form-group-auth'>
                                <Field name='activemedia_auth'>
                                    {({ input, meta }) => (
                                        <>
                                            <label htmlFor='activemedia_auth'>Please put the word activemedia into this field: *</label>
                                            <input {...input} type='text'/>
                                            {meta.error && meta.touched && (
                                                <span className='form-error-message'>{meta.error}</span>
                                            )}
                                        </>
                                    )}
                                </Field>
                            </div>
                        </div>
                        <div className="submit-button__container">
                            <button
                                disabled={submitting}
                                className='btn submit'
                                data-text='submit'
                                type='submit'>
                                Send
                            </button>
                        </div>
                    </div>
                </form>
            )}
        />
    );
}

const handleSubmit = async values => {
    let base_data = {
        webform_id: 'contact',
        in_draft: false
    };
    const data = {
        ...base_data,
        ...values
    };
    //console.log(data);

    const response = await axios.post(
        'https://backend.activemedia.com/react_webform_backend/submit',
        {
            ...data
        }
    );
    if (response.data.error) {
        //console.log("hahaa");
        //throw new WebformError(response);
    } else {
        navigate("/thank-you");
    }
    //console.log(response);
    //console.log('Submitted');
}

export default ContactWebform;