import React from 'react';

export function PortfolioFilter() {
    var value = 'all';

    const select=(e)=>{
        value = e
        //console.log(value)

        if (value === "all") {

            var sidebardivms = document.getElementsByClassName("project__boxes--box");
            
            for (var i = 0; i < sidebardivms.length; i++) {
                sidebardivms[i].classList.add('active-portfolio');
            }

            var allfilterid = document.getElementById("all");
            var allfilterbox = document.getElementsByClassName("portfolio-filter");

            Array.from(allfilterbox).forEach(function (x) {
                if (x.classList.contains("active-filter")) {
                    x.classList.remove("active-filter");
                }
            })
            allfilterid.classList.add("active-filter");


        } if ( value === "online-marketing") {
            var onlinedivid1 = document.getElementById("project-class-1");
            var onlinedivid2 = document.getElementById("project-class-3");
            var onlinedivid3 = document.getElementById("project-class-5");
            var onlinedivid4 = document.getElementById("project-class-6");
            var onlinedivid5 = document.getElementById("project-class-7");
            var onlinedivid6 = document.getElementById("project-class-8");
            var sidebardivms = document.getElementsByClassName("project__boxes--box");

            Array.from(sidebardivms).forEach(function (x) {
                if (x.classList.contains("active-portfolio")) {
                    x.classList.remove("active-portfolio");
                }
            })
            onlinedivid1.classList.add("active-portfolio");
            onlinedivid2.classList.add("active-portfolio");
            onlinedivid3.classList.add("active-portfolio");
            onlinedivid4.classList.add("active-portfolio");
            onlinedivid5.classList.add("active-portfolio");
            onlinedivid6.classList.add("active-portfolio");

            allfilterid = document.getElementById("online-marketing");
            allfilterbox = document.getElementsByClassName("portfolio-filter");

            Array.from(allfilterbox).forEach(function (x) {
                if (x.classList.contains("active-filter")) {
                    x.classList.remove("active-filter");
                }
            })
            allfilterid.classList.add("active-filter");

        } if ( value === "web-design") {
            var webdesdivid1 = document.getElementById("project-class-1");
            var webdesdivid2 = document.getElementById("project-class-3");
            var webdesdivid3 = document.getElementById("project-class-4");
            var webdesdivid4 = document.getElementById("project-class-5");
            var webdesdivid5 = document.getElementById("project-class-6");
            var webdesdivid6 = document.getElementById("project-class-8");
            sidebardivms = document.getElementsByClassName("project__boxes--box");

            Array.from(sidebardivms).forEach(function (x) {
                if (x.classList.contains("active-portfolio")) {
                    x.classList.remove("active-portfolio");
                }
            })
            webdesdivid1.classList.add("active-portfolio");
            webdesdivid2.classList.add("active-portfolio");
            webdesdivid3.classList.add("active-portfolio");
            webdesdivid4.classList.add("active-portfolio");
            webdesdivid5.classList.add("active-portfolio");
            webdesdivid6.classList.add("active-portfolio");

            allfilterid = document.getElementById("web-design");
            allfilterbox = document.getElementsByClassName("portfolio-filter");

            Array.from(allfilterbox).forEach(function (x) {
                if (x.classList.contains("active-filter")) {
                    x.classList.remove("active-filter");
                }
            })
            allfilterid.classList.add("active-filter");

        } if ( value === "mobile-application") {
            var mobileappdivid = document.getElementById("project-class-1");
            sidebardivms = document.getElementsByClassName("project__boxes--box");

            Array.from(sidebardivms).forEach(function (x) {
                if (x.classList.contains("active-portfolio")) {
                    x.classList.remove("active-portfolio");
                }
            })
            mobileappdivid.classList.add("active-portfolio");

            allfilterid = document.getElementById("mobile-application");
            allfilterbox = document.getElementsByClassName("portfolio-filter");

            Array.from(allfilterbox).forEach(function (x) {
                if (x.classList.contains("active-filter")) {
                    x.classList.remove("active-filter");
                }
            })
            allfilterid.classList.add("active-filter");
        }
    }

    return (
      <div className="portfolio-filter__container" key={value+ Math.floor(Math.random() * 9999)}>
          <div className="portfolio-filter portfolio-filter-all active-filter" id="all" onClick={() => select("all")}>All</div>
          <div className="portfolio-filter portfolio-filter-online-marketing" id="online-marketing" onClick={() => select("online-marketing")}>Online Marketing</div>
          <div className="portfolio-filter portfolio-filter-web-design" id="web-design" onClick={() => select("web-design")}>Web Design & Development</div>
          <div className="portfolio-filter portfolio-filter-mobile-application" id="mobile-application" onClick={() => select("mobile-application")}>Mobile Application Development</div>
      </div>
    );
}

export default PortfolioFilter;