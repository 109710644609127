import React, { useState } from 'react';
import { navigate } from 'gatsby';
import Paypal from 'gatsby-plugin-paypal';

const PaypalButton = () => {
    const [message, setMessage] = useState(null);

    return (
        <div className='paypal-container'>
            <h1>Global Forum Ticket Options</h1>
            {message ? <div className='paypal-status'>{message}</div> : null}
            <div className='paypal-infobox'>
                <p>
                    Gisco startup competition: October 18, PM: 1:00-4:00 <strong>$350</strong>
                </p>
                <p>
                    Global Forum conference participation and Gisco startup competition: Conference
                    October 17-18 - 2 days <strong>$700</strong>
                </p>
            </div>

            <div className='paypal-pay'>
                <div className='paypal-350'>
                    <Paypal
                        style={{
                            shape: 'rect',
                            color: 'blue',
                            layout: 'horizontal',
                            label: 'paypal',
                            tagline: false
                        }}
                        amount={350}
                        currency='USD'
                        onApprove={() => {
                            navigate('/gf-payment/thank-you');
                        }}
                        onError={() => {
                            setMessage('Paypal payment error!');
                        }}
                        onCancel={() => {
                            setMessage('Paypal payment cancelled!');
                        }}
                    />
                </div>
                <div className='paypal-700'>
                    <Paypal
                        style={{
                            shape: 'rect',
                            color: 'blue',
                            layout: 'horizontal',
                            label: 'paypal',
                            tagline: false
                        }}
                        amount={700}
                        currency='USD'
                        onApprove={() => {
                            navigate('/gf-payment/thank-you');
                        }}
                        onError={() => {
                            setMessage('Paypal payment error!');
                        }}
                        onCancel={() => {
                            setMessage('Paypal payment cancelled!');
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default PaypalButton;
