export const getFile = (id, files) => {
    let image;
    if (files) {
        if (files?.drupal?.mediaQuery) {
        for (const item of files.drupal.mediaQuery.entities) {

            if (item.entityUuid === id) {
                image = item;
            }
        }
    }
    if (files?.drupal?.fileQuery) {
        for (const item2 of files.drupal.fileQuery.entities) {
            
            if (item2.uuid === id) {
                image = item2;
            }
        }
    }
    }
    
    return image
    //return files.drupal.mediaQuery.entities[10]
};