import React,{useState} from 'react';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown'
import '../utils/fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function AskForQuoteDropdown() {
    const [value,setValue]=useState('Web Design & Development');
    const handleSelect=(e)=>{
      setValue(e)

    if (e === "Web Design & Development"){
        e = "ask-for-quote__form-container--web-design"
    } else if (e === "Mobile Services & Applications") {
        e = "ask-for-quote__form-container--mobile-services"
    }

    if (document.getElementById) {
        var formdivid = document.getElementById(e);
        var formdivs = document.getElementsByClassName("ask-for-quote__form-container");

        Array.from(formdivs).forEach(function (x) {
            if (x.classList.contains("active-webform")) {
                x.classList.remove("active-webform");
            }
        })
        formdivid.classList.add("active-webform");
    }

    if (e === "ask-for-quote__form-container--mobile-services") {
      e = "mobile-services__container--button"
      var mobiledivid = document.getElementById(e);
      var sidebardivms = document.getElementsByClassName("ask-for-quote__buttons--container");

      Array.from(sidebardivms).forEach(function (x) {
        if (x.classList.contains("active-button")) {
            x.classList.remove("active-button");
        }
      })
      mobiledivid.classList.add("active-button");
    } else if (e === "ask-for-quote__form-container--web-design") {
      e = "web-design__container--button"
      var webdivid = document.getElementById(e);
      var sidebardivwd = document.getElementsByClassName("ask-for-quote__buttons--container");

      Array.from(sidebardivwd).forEach(function (x) {
        //console.log(x);
        if (x.classList.contains("active-button")) {
            x.classList.remove("active-button");
        }
      })
      webdivid.classList.add("active-button");
    }
    return false;

    }

    return (
      <div className="ask-for-quote__dropdown--container" id="ask-for-quote--top" key={value+ Math.floor(Math.random() * 9999)}>
        <div className="ask-for-quote--container--box">
        <DropdownButton
        alignRight
        title={value}
        id="dropdown-menu-align-right"
        onSelect={handleSelect}
          >
           <Dropdown.Item eventKey="Web Design & Development">Web Design & Development</Dropdown.Item>
           <Dropdown.Divider />
           <Dropdown.Item eventKey="Mobile Services & Applications">Mobile Services & Applications</Dropdown.Item>
           </DropdownButton>
           <div className="dropdown-menu-chevron"><FontAwesomeIcon icon="chevron-down" /></div>
           </div>
      </div>
    );
}

export default AskForQuoteDropdown;