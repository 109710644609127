import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { convertNodeToElement } from 'react-html-parser';
import sanitizeHtml from 'sanitize-html';
import DrupalFile from '../components/file.js';
import { AskForQuoteWebDesign } from '../components/forms/askforquotewebdesign';
import { AskForQuoteDropdown } from '../components/askforquotedropdown';
import { ContactWebform } from '../components/forms/contactwebform';
import { WebRankingReport } from '../components/forms/webrankingreport';
import { PortfolioFilter } from '../components/portfoliofilter';
import PaypalButton from '../components/paypal';

const HtmlParser = ({ html, wrapperClass = 'textarea' }) => {
    const processBody = (body) => {
        body = sanitizeHtml(body, {
            allowedTags: sanitizeHtml.defaults.allowedTags.concat([
                'drupal-media',
                'address',
                'article',
                'aside',
                'footer',
                'header',
                'h1',
                'h2',
                'h3',
                'h4',
                'h5',
                'h6',
                'hgroup',
                'main',
                'nav',
                'section',
                'blockquote',
                'dd',
                'div',
                'dl',
                'dt',
                'figcaption',
                'figure',
                'hr',
                'li',
                'main',
                'ol',
                'p',
                'pre',
                'ul',
                'a',
                'abbr',
                'b',
                'bdi',
                'bdo',
                'br',
                'cite',
                'code',
                'data',
                'dfn',
                'em',
                'i',
                'kbd',
                'mark',
                'q',
                'rb',
                'rp',
                'rt',
                'rtc',
                'ruby',
                's',
                'samp',
                'small',
                'span',
                'strong',
                'sub',
                'sup',
                'time',
                'u',
                'var',
                'wbr',
                'caption',
                'col',
                'colgroup',
                'table',
                'tbody',
                'td',
                'tfoot',
                'th',
                'thead',
                'tr'
            ]),
            allowedAttributes: Object.assign(sanitizeHtml.defaults.allowedAttributes, {
                'drupal-media': ['data-*', 'data-text', 'data-entity-type'],
                '*': ['class', 'href', 'data-text', 'id', 'rel', 'style', 'colspan']
            })
        });

        return ReactHtmlParser(body, {
            transform: (node, index) => {
                //node = findAndReplaceEmail(node);

                if (node.type === 'tag' && node.attribs['data-entity-type'] === 'media') {
                    let uuid = node.attribs['data-entity-uuid'];
                    return <DrupalFile id={uuid} />;
                }
                if (node?.children?.length > 0) {
                    node.children.forEach((child, index) => {
                        if (child.type === 'tag' && node.attribs['data-entity-type'] === 'media') {
                            let uuid = child.attribs['data-entity-uuid'];
                            return <DrupalFile id={uuid} />;
                        }
                    });
                }
                node = findAndReplaceTokens(node);
                return convertNodeToElement(node, index);
            }
        });
    };
    const findAndReplaceTokens = (node) => {
        if (node.data) {
            switch (node.data) {
                case '[webform:askforquote]':
                    node.data = <AskForQuoteWebDesign key={Math.floor(Math.random() * 9999)} />;
                    break;
                case '[webform:askforquotedropdown]':
                    node.data = <AskForQuoteDropdown key={Math.floor(Math.random() * 9999)} />;
                    break;
                case '[webform:contact]':
                    node.data = <ContactWebform key={Math.floor(Math.random() * 9999)} />;
                    break;
                case '[webform:webrankingreport]':
                    node.data = <WebRankingReport key={Math.floor(Math.random() * 9999)} />;
                    break;
                case '[webform:portfoliofilter]':
                    node.data = <PortfolioFilter key={Math.floor(Math.random() * 9999)} />;
                    break;
                case '[payment:paypal]':
                    node.data = <PaypalButton key={Math.floor(Math.random() * 9999)} />;
                    break;
                default:
                    break;
            }
        }
        if (node.children) {
            for (const index in node.children) {
                findAndReplaceTokens(node.children[index]);
            }
        }
        return node;
    };
    /*
    const findAndReplaceEmail = (node) => {
        let modified = false;
        if (node.name === 'a' && node.attribs.href) {

            //console.log(node.attribs.href);

            if (node.attribs.href.includes('mailto:')) {
                const email = node.attribs.href.replace('mailto:', ''); // test@prompt.something.hu
                const beforeAt = email.split('@')[0]; // test
                const afterAt = email.split('@')[1]; // @prompt.something.hu
                const _splitArr = afterAt.split('.'); // ['prompt', 'something', 'hu']
                const domain = _splitArr.slice(0, _splitArr.length - 1).join('.'); // prompt.something
                const tdl = _splitArr[_splitArr.length - 1]; // .hu

                // src: https://stackoverflow.com/a/41566570
                node.attribs.class = 'definitely-not-an-email';
                node.attribs['data-name'] = beforeAt;
                node.attribs['data-domain'] = domain;
                node.attribs['data-tld'] = tdl;
                node.children[0].data = '';
                modified = true;
            }
            
        }
        if (node.children) {
            for (const index in node.children) {
                //findAndReplaceEmail(node.children[index]);
            }
        }
        return { ...node, modified: modified };
    };
    */
    return <div className={wrapperClass}>{processBody(html)}</div>;
};
export default HtmlParser;
