import React,{useState} from 'react';
import { Link, navigate } from 'gatsby';
import { Form, Field } from 'react-final-form';
import axios from 'axios';
import '../../utils/fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function goTop() {
    const top = document.getElementById("ask-for-quote--top");
    top.scrollIntoView()
}

function activeSheetBtn(count) {
    
    if(count === 0){
        var id = "ask-for-quote__form-container--web-design--1";
        var ids = "ask-for-quote__form-container--mobile-services--1"
        var sidebarwdid = "ask-for-quote-wd-1"
        var sidebarmsid = "ask-for-quote-ms-1"
        
    }
    if(count === 1){
        id = "ask-for-quote__form-container--web-design--2";
        ids = "ask-for-quote__form-container--mobile-services--2"
        sidebarwdid = "ask-for-quote-wd-2"
        sidebarmsid = "ask-for-quote-ms-2"
    }
    if(count === 2){
        id = "ask-for-quote__form-container--web-design--3";
        ids = "ask-for-quote__form-container--mobile-services--3"
        sidebarwdid = "ask-for-quote-wd-3"
        sidebarmsid = "ask-for-quote-ms-3"
    }
    if(count === 3){
        id = "ask-for-quote__form-container--web-design--4";
        ids = "ask-for-quote__form-container--mobile-services--4"
        sidebarwdid = "ask-for-quote-wd-4"
        sidebarmsid = "ask-for-quote-ms-4"
    }
    if(count === 4){
        id = "ask-for-quote__form-container--web-design--5";
        ids = "ask-for-quote__form-container--mobile-services--5"
        sidebarwdid = "ask-for-quote-wd-5"
        sidebarmsid = "ask-for-quote-ms-5"
    }
    else {
        //console.log("wtf")
        //console.log(count)
    }

    if (document.getElementById) {
        var webdivid = document.getElementById(id);
        var webdivs = document.getElementsByClassName("ask-for-quote__form-container--web-design--sheet");
        var mobiledivid = document.getElementById(ids);
        var mobiledivs = document.getElementsByClassName("ask-for-quote__form-container--mobile-services--sheet");
        var webdivbtnwdid = document.getElementById(sidebarwdid);
        var webdivbtnmsid = document.getElementById(sidebarmsid);
        var webbtndivs = document.getElementsByClassName("ask_for_quote__trigger");

        Array.from(webdivs).forEach(function (web) {
            if (web.classList.contains("active-sheet")) {
                web.classList.remove("active-sheet");
            }
        })
        Array.from(mobiledivs).forEach(function (mobile) {
            if (mobile.classList.contains("active-sheet")) {
                mobile.classList.remove("active-sheet");
            }
        })
        Array.from(webbtndivs).forEach(function (wbbtn) {
            if (wbbtn.classList.contains("active-trigger")) {
                wbbtn.classList.remove("active-trigger");
            }
        })
        mobiledivid.classList.add("active-sheet");
        webdivid.classList.add("active-sheet");
        webdivbtnwdid.classList.add("active-trigger");
        webdivbtnmsid.classList.add("active-trigger");
    }
    return false;
}

const HandleButtons = ({submitting, count, setCount}) => {

    if (count === 0 ) {
        return (
            <button
                onClick={() => {setCount(count + 1); activeSheetBtn(count + 1); goTop();}}
                className='btn next btn-active'
                data-text='next'
                type='button'>
                Next
            </button>
        )
    }
    if (count < 4) {
        return (
            <>
                <button
                    onClick={() => {setCount(count - 1); activeSheetBtn(count - 1); goTop();}}
                    className='btn prev'
                    data-text='prev'
                    type='button'>
                    Prev
                </button>
                <button
                    onClick={() => {setCount(count + 1); activeSheetBtn(count + 1); goTop();}}
                    disabled={submitting}
                    className='btn next btn-active'
                    data-text='next'
                    type='button'>
                    Next
                </button>
            </>
        )
    }
    if (count <= 5) {
        return (
            <>
                <button
                    onClick={() => {setCount(count - 1); activeSheetBtn(count - 1); goTop();}}
                    className='btn prev'
                    data-text='prev'
                    type='button'>
                    Prev
                </button>
                <div className="submit-button__container">
                    <button
                        disabled={submitting}
                        className='btn submit'
                        data-text='submit'
                        type='submit'>
                        Submit
                    </button>
                </div>
            </>
        )
    }
    
}

export function AskForQuoteWebDesign({ page_data }) {
    const [count, setCount] = useState(0);

    return (
        <Form
            validate={values => {
                const errors = {}
                if (!values.activemedia_auth) {
                errors.activemedia_auth = 'Required'
                } else if (values.activemedia_auth !== "activemedia") {
                errors.activemedia_auth = 'Must match'
                }
                return errors
            }}
            onSubmit={handleSubmit}
            render={({ handleSubmit, form, submitting, pristine }) => (
                <div className="ask-for-quote__main-container">
                    <div className="ask-for-quote__buttons--container active-button" id="web-design__container--button">
                        <div className="ask-for-quote__sidebar" id="web-design__container--button">
                            <div className="ask-for-quote__sidebar--container">
                                <div aria-hidden="true" id="ask-for-quote-wd-1" onKeyDown={() => {setCount(0); activeSheetBtn(0)}} className="ask_for_quote__trigger active-trigger" onClick={() => {setCount(0); activeSheetBtn(0)}}>
                                    <div className="hide-this-element">
                                    <h2>Your Organization & Website</h2>
                                    </div>
                                    <div className="ask-for-quote__sidebar--container--fafaicon">
                                        <div className="ask-for-quote__sidebar--container--fafaicon--container">
                                            <FontAwesomeIcon icon={'building'} />
                                        </div>
                                    </div>
                                    <div className="ask-for-quote-sidebar-text">
                                        1. Your Organization & Website
                                    </div>
                                </div>
                                <div aria-hidden="true" id="ask-for-quote-wd-2" onKeyDown={() => {setCount(1); activeSheetBtn(1)}} className="ask_for_quote__trigger" onClick={() => {setCount(1); activeSheetBtn(1)}}>
                                    <div className="hide-this-element">
                                    <h2>Website Basics</h2>
                                    </div>
                                    <div className="ask-for-quote__sidebar--container--fafaicon">
                                        <div className="ask-for-quote__sidebar--container--fafaicon--container">
                                            <FontAwesomeIcon icon={'globe-americas'} />
                                        </div>
                                    </div>
                                    <div className="ask-for-quote-sidebar-text">
                                        2. Website Basics
                                    </div>
                                </div>
                                <div aria-hidden="true" id="ask-for-quote-wd-3" onKeyDown={() => {setCount(2); activeSheetBtn(2)}} className="ask_for_quote__trigger" onClick={() => {setCount(2); activeSheetBtn(2)}}>
                                    <div className="hide-this-element">
                                    <h2>Website Structure & Features</h2>
                                    </div>
                                    <div className="ask-for-quote__sidebar--container--fafaicon">
                                        <div className="ask-for-quote__sidebar--container--fafaicon--container">
                                            <FontAwesomeIcon icon={'cogs'} />
                                        </div>
                                    </div>
                                    <div className="ask-for-quote-sidebar-text">
                                        3. Website Structure & Features
                                    </div>
                                </div>
                                <div aria-hidden="true" id="ask-for-quote-wd-4" onKeyDown={() => {setCount(3); activeSheetBtn(3)}} className="ask_for_quote__trigger" onClick={() => {setCount(3); activeSheetBtn(3)}}>
                                    <div className="hide-this-element">
                                    <h2>Content & Images</h2>
                                    </div>
                                    <div className="ask-for-quote__sidebar--container--fafaicon">
                                        <div className="ask-for-quote__sidebar--container--fafaicon--container">
                                            <FontAwesomeIcon icon={'lightbulb'} />
                                        </div>
                                    </div>
                                    <div className="ask-for-quote-sidebar-text">
                                        4. Content & Images
                                    </div>
                                </div>
                                <div aria-hidden="true" id="ask-for-quote-wd-5" onKeyDown={() => {setCount(4); activeSheetBtn(4)}} className="ask_for_quote__trigger" onClick={() => {setCount(4); activeSheetBtn(4)}}>
                                    <div className="hide-this-element">
                                    <h2>General Information</h2>
                                    </div>
                                    <div className="ask-for-quote__sidebar--container--fafaicon">
                                        <div className="ask-for-quote__sidebar--container--fafaicon--container">
                                            <FontAwesomeIcon icon={'file-alt'} />
                                        </div>
                                    </div>
                                    <div className="ask-for-quote-sidebar-text">
                                        5. General Information
                                    </div>
                                </div>
                            </div>
                        </div>     
                    </div>
                    <div className="ask-for-quote__buttons--container" id="mobile-services__container--button">
                        <div className="ask-for-quote__sidebar" id="web-design__container--button">
                            <div className="ask-for-quote__sidebar--container">
                                <div aria-hidden="true" id="ask-for-quote-ms-1" onKeyDown={() => {setCount(0); activeSheetBtn(0)}} className="ask_for_quote__trigger active-trigger" onClick={() => {setCount(0); activeSheetBtn(0)}}>
                                    <div className="hide-this-element">
                                    <h2>Your Organization & App Goals</h2>
                                    </div>
                                    <div className="ask-for-quote__sidebar--container--fafaicon">
                                        <div className="ask-for-quote__sidebar--container--fafaicon--container">
                                            <FontAwesomeIcon icon={'building'} />
                                        </div>
                                    </div>
                                    <div className="ask-for-quote-sidebar-text">
                                        1. Your Organization & App Goals
                                    </div>
                                </div>
                                <div aria-hidden="true" id="ask-for-quote-ms-2" onKeyDown={() => {setCount(1); activeSheetBtn(1)}} className="ask_for_quote__trigger" onClick={() => {setCount(1); activeSheetBtn(1)}}>
                                    <div className="hide-this-element">
                                    <h2>Application Basics</h2>
                                    </div>
                                    <div className="ask-for-quote__sidebar--container--fafaicon">
                                        <div className="ask-for-quote__sidebar--container--fafaicon--container">
                                            <FontAwesomeIcon icon={'globe-americas'} />
                                        </div>
                                    </div>
                                    <div className="ask-for-quote-sidebar-text">
                                        2. Application Basics
                                    </div>
                                </div>
                                <div aria-hidden="true" id="ask-for-quote-ms-3" onKeyDown={() => {setCount(2); activeSheetBtn(2)}} className="ask_for_quote__trigger" onClick={() => {setCount(2); activeSheetBtn(2)}}>
                                    <div className="hide-this-element">
                                    <h2>Application Screens & Features</h2>
                                    </div>
                                    <div className="ask-for-quote__sidebar--container--fafaicon">
                                        <div className="ask-for-quote__sidebar--container--fafaicon--container">
                                            <FontAwesomeIcon icon={'cogs'} />
                                        </div>
                                    </div>
                                    <div className="ask-for-quote-sidebar-text">
                                        3. Application Screens & Features
                                    </div>
                                </div>
                                <div aria-hidden="true" id="ask-for-quote-ms-4" onKeyDown={() => {setCount(3); activeSheetBtn(3)}} className="ask_for_quote__trigger" onClick={() => {setCount(3); activeSheetBtn(3)}}>
                                    <div className="hide-this-element">
                                    <h2>Content & Images</h2>
                                    </div>
                                    <div className="ask-for-quote__sidebar--container--fafaicon">
                                        <div className="ask-for-quote__sidebar--container--fafaicon--container">
                                            <FontAwesomeIcon icon={'lightbulb'} />
                                        </div>
                                    </div>
                                    <div className="ask-for-quote-sidebar-text">
                                        4. Content & Images
                                    </div>
                                </div>
                                <div aria-hidden="true" id="ask-for-quote-ms-5" onKeyDown={() => {setCount(4); activeSheetBtn(4)}} className="ask_for_quote__trigger" onClick={() => {setCount(4); activeSheetBtn(4)}}>
                                    <div className="hide-this-element">
                                    <h2>General Information</h2>
                                    </div>
                                    <div className="ask-for-quote__sidebar--container--fafaicon">
                                        <div className="ask-for-quote__sidebar--container--fafaicon--container">
                                            <FontAwesomeIcon icon={'file-alt'} />
                                        </div>
                                    </div>
                                    <div className="ask-for-quote-sidebar-text">
                                        5. General Information
                                    </div>
                                </div>
                            </div>
                        </div>     
                    </div>
                    <form onSubmit={handleSubmit} noValidate>
                        <div className="ask-for-quote__form-container ask-for-quote__form-container--web-design active-webform" id="ask-for-quote__form-container--web-design">
                            <div className="ask-for-quote__form-container--sheet ask-for-quote__form-container--web-design--sheet ask-for-quote__form-container--web-design--1 active-sheet" id="ask-for-quote__form-container--web-design--1">
                                <h2>1. YOUR ORGANIZATION & WEBSITE</h2>
                                <div className='form-group'>
                                    <label htmlFor='web_overview'>1.1. Provide a brief, general overview of your organization (e.g. mission or purpose) and information about your services/products.</label>
                                    <Field name="web_overview" component="textarea" />
                                </div>
                                <div className='form-group'>
                                    <label htmlFor='web_adjectives'>1.2. Please list up to 10 adjectives that best describe your target audience.</label>
                                    <Field name="web_adjectives" component="textarea" />
                                </div>
                                <div className='form-group'>
                                    <label htmlFor='web_assessment'>1.3. Please give us your honest assessment of your current website from a stylistic and functional standpoint. Focus specifically on the elements you dislike.</label>
                                    <Field name="web_assessment" component="textarea" />
                                </div>
                                <div className='form-group'>
                                    <label htmlFor='web_examples'>1.4. Please give us 2-3 examples of websites you like the look & feel of, plus the main reasons why you like them (can be competitor websites or any other website).</label>
                                    <Field name="web_examples" component="textarea" />
                                </div>
                                <div className='form-group'>
                                    <label htmlFor='web_assets'>1.5. Does your company have any other online assets (other websites, partner sites, mini sites, web applications etc. )?</label>
                                    <Field name="web_assets" component="textarea" />
                                </div>
                            </div>

                            <div className="ask-for-quote__form-container--sheet ask-for-quote__form-container--web-design--sheet ask-for-quote__form-container--web-design--2" id="ask-for-quote__form-container--web-design--2">
                                <h2>2. WEBSITE BASICS</h2>
                                <p>2.1. Please rate the importance of the following factors on a scale of 1 to 10.</p>
                                <div className="form-group">
                                    <label htmlFor="speed_of_development">Speed of Development</label>
                                    <p>1. There is no pressing need for the website to go live.</p>
                                    <p>10. Go live ASAP.</p>
                                    <Field name="web_speed_of_development" component="select">
                                        <option>- None -</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                    </Field>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="mobile_optimization">Mobile Optimization</label>
                                    <p>1. Responsive design that displays optimally on most devices and screen sizes.</p>
                                    <p>10. Responsive design and extensive testing, with special attention to both mobile & desktop devices to ensure optimal viewability on all screen sizes.</p>
                                    <Field name="web_mobile_optimization" component="select">
                                        <option>- None -</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                    </Field>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="editing">Editing</label>
                                    <p>1. Standard content management and website editing features that may require a slight learning curve.</p>
                                    <p>10. Highly customized backend interface that makes content management a breeze</p>
                                    <Field name="web_editing" component="select">
                                        <option>- None -</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                    </Field>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="design_variety">Design Variety</label>
                                    <p>1. A few different designs for different types of pages.</p>
                                    <p>10. Lots of custom (at least 40% different) page designs.</p>
                                    <Field name="web_design_variety" component="select">
                                        <option>- None -</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                    </Field>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="details">Details</label>
                                    <p>1. Keep it simple.</p>
                                    <p>10. Get fancy. More complex solutions gadgets and extra elements (e.g. sliders, etc.)</p>
                                    <Field name="web_details" component="select">
                                        <option>- None -</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                    </Field>
                                </div>
                                <div className='form-group'>
                                    <label htmlFor='web_important_action'>2.2. What is the single most important action you’d like visitors to take before they exit the website? If there are other important actions you’d like the visitors of your site to take, please list them here.</label>
                                    <Field name="web_important_action" component="textarea" />
                                </div>
                            </div>
                            <div className="ask-for-quote__form-container--sheet ask-for-quote__form-container--web-design--sheet ask-for-quote__form-container--web-design--3" id="ask-for-quote__form-container--web-design--3">
                                <h2>3. WEBSITE STRUCTURE & FEATURES</h2>
                                <p><span>Our websites come with responsive design (optimized for all devices: desktop, mobile, tablet and other devices) and are SEO-ready (technically prepared for search engines to index).</span></p>
                                <div className='form-group'>
                                    <label htmlFor='web_main_sections'>3.1. Please list the main sections you’d like on your new website and add a short description for each (include everything you think is important).</label>
                                    <Field name="web_main_sections" component="textarea" />
                                    <p>You may use the structure of your existing website. We can also use a website that you like as a blueprint for your new site’s structure. In that case, list all of the sections the blueprint website has and indicate which sections you’d like on your own website. Then, list any section the blueprint website doesn’t have, but that you’d want on your site. </p>
                                    <p>Feel free to add everything that you have in mind – even if you are unsure whether it will work or have other questions. After the project kick-off we’ll send out a planned sitemap that specifies sections and pages.</p>
                                </div>
                                <div className='form-group'>
                                    <label htmlFor='web_general_features'>3.2. What general features and functionalities do you plan to have on your new site?</label>
                                    <Field name="web_general_features" component="textarea" />
                                    <p>To guide you we’ve listed some common features: Site Search Engine (internal), Social Media Links (Facebook, LinkedIn, Twitter, etc.), Survey or Feedback Forms (if so, how many?), Mailing list Subscription (email newsletters), Multi-language Support. If you have any additional features in mind please describe them.</p>
                                </div>
                                <div className='form-group'>
                                    <label htmlFor='web_website_components'>3.3. Do you plan to have a web application or other special website component added to the website (e.g. Online Payment Integration (PayPal etc., online shop with shopping cart, reservation system, sales system, Password Protection for Certain Pages)? If so, please briefly describe your request.</label>
                                    <Field name="web_website_components" component="textarea" />
                                </div>
                            </div>
                            <div className="ask-for-quote__form-container--sheet ask-for-quote__form-container--web-design--sheet ask-for-quote__form-container--web-design--4" id="ask-for-quote__form-container--web-design--4">
                                <h2>4. CONTENT & IMAGES</h2>
                                <p><span>A new website always requires existing content to be updated and/or new content to be created (new landing pages, specific section texts, visual content etc.). During the website development we will assist you by providing a content guide which shows what kind of texts and images are missing and on which pages. For missing images we’ll give suggestions for available visual solutions, like stock photos.</span></p>
                                <div className='form-group'>
                                    <label htmlFor='web_content_images_help'>4.1. We assume that most of the content for the new website will be created by migrating content from the existing website, and that any new content will be created by your team. However, our skilled copywriters are also at your disposal to develop fresh content for the new website. Please let us know if you’d like us to help with content development and to what extent.</label>
                                    <Field name="web_content_images_help" component="textarea" />
                                </div>
                                <div className='form-group'>
                                    <label htmlFor='web_content_images_provide'>4.2. Can you provide us with materials, such as high quality photos and videos related to your products/services, for use on your website? Please describe what kind of material you have available.</label>
                                    <Field name="web_content_images_provide" component="textarea" />
                                </div>       
                            </div>
                            <div className="ask-for-quote__form-container--sheet ask-for-quote__form-container--web-design--sheet ask-for-quote__form-container--mobile-services--sheet ask-for-quote__form-container--mobile-services--5 ask-for-quote__form-container--web-design--5" id="ask-for-quote__form-container--web-design--5">
                                <h2>5. GENERAL INFORMATION</h2>
                                <div className='form-group'>
                                    <Field name='company_name'>
                                        {({ input, meta }) => (
                                            <>
                                                <label htmlFor='company_name'>Company Name</label>
                                                <input {...input} type='text'/>
                                                {meta.error && meta.touched && (
                                                    <span className='form-error-message'>{meta.error}</span>
                                                )}
                                            </>
                                        )}
                                    </Field>
                                </div>
                                <div className='form-group'>
                                    <Field name='website'>
                                        {({ input, meta }) => (
                                            <>
                                                <label htmlFor='website'>Website</label>
                                                <input {...input} type='text'/>
                                                {meta.error && meta.touched && (
                                                    <span className='form-error-message'>{meta.error}</span>
                                                )}
                                            </>
                                        )}
                                    </Field>
                                </div>
                                <div className='form-group'>
                                    <Field name='name'>
                                        {({ input, meta }) => (
                                            <>
                                                <label htmlFor='name'>Name</label>
                                                <input {...input} type='text'/>
                                                {meta.error && meta.touched && (
                                                    <span className='form-error-message'>{meta.error}</span>
                                                )}
                                            </>
                                        )}
                                    </Field>
                                </div>
                                <div className='form-group'>
                                    <Field name='position'>
                                        {({ input, meta }) => (
                                            <>
                                                <label htmlFor='position'>Position</label>
                                                <input {...input} type='text'/>
                                                {meta.error && meta.touched && (
                                                    <span className='form-error-message'>{meta.error}</span>
                                                )}
                                            </>
                                        )}
                                    </Field>
                                </div>
                                <div className='form-group'>
                                    <Field name='email'>
                                        {({ input, meta }) => (
                                            <>
                                                <label htmlFor='email'>Email</label>
                                                <input {...input} type='text'/>
                                                {meta.error && meta.touched && (
                                                    <span className='form-error-message'>{meta.error}</span>
                                                )}
                                            </>
                                        )}
                                    </Field>
                                </div>
                                <div className='form-group'>
                                    <Field name='phone'>
                                        {({ input, meta }) => (
                                            <>
                                                <label htmlFor='phone'>Phone</label>
                                                <input {...input} type='text'/>
                                                {meta.error && meta.touched && (
                                                    <span className='form-error-message'>{meta.error}</span>
                                                )}
                                            </>
                                        )}
                                    </Field>
                                </div>
                                <div className='form-group'>
                                    <Field name='activemedia_auth'>
                                        {({ input, meta }) => (
                                            <>
                                                <label htmlFor='activemedia_auth'>Please put the word activemedia into this field: *</label>
                                                <input {...input} type='text'/>
                                                {meta.error && meta.touched && (
                                                    <span className='form-error-message'>{meta.error}</span>
                                                )}
                                            </>
                                        )}
                                    </Field>
                                </div>
                            </div>
                        </div>
                        <div className="ask-for-quote__form-container ask-for-quote__form-container--mobile-services" id="ask-for-quote__form-container--mobile-services">
                            <div className="ask-for-quote__form-container--mobile-services--sheet ask-for-quote__form-container--sheet ask-for-quote__form-container--mobile-services--1 active-sheet" id="ask-for-quote__form-container--mobile-services--1">
                                <h2>1. YOUR ORGANIZATION & APP GOALS</h2>
                                <div className='form-group'>
                                    <label htmlFor='mobile_overview'>1.1. Provide a brief, general overview of your organization (e.g. mission or purpose) and information about your services/products.</label>
                                    <Field name="mobile_overview" component="textarea" />
                                </div>
                                <div className='form-group'>
                                    <label htmlFor='mobile_adjectives'>1.2. Please list up to 10 adjectives that best describe your target audience.</label>
                                    <Field name="mobile_adjectives" component="textarea" />
                                </div>
                                <div className='form-group'>
                                    <label htmlFor='mobile_examples'>1.3. Please give us 2-3 examples of applications that you like the look & feel of, plus the main reasons why you like them (these examples can be competitor applications or any other application).</label>
                                    <Field name="mobile_examples" component="textarea" />
                                </div>
                                <div className='form-group'>
                                    <label htmlFor='mobile_existing_application'>1.4. Does your company have an existing application? If so, please briefly describe how it works.</label>
                                    <Field name="mobile_existing_application" component="textarea" />
                                </div>
                                <div className='form-group'>
                                    <label htmlFor='mobile_assets'>1.5. Does your company have any other online assets (other websites, partner sites, mini sites, web applications etc. )?</label>
                                    <Field name="mobile_assets" component="textarea" />
                                </div>
                            </div>
                            <div className="ask-for-quote__form-container--sheet ask-for-quote__form-container--mobile-services--sheet ask-for-quote__form-container--mobile-services--2" id="ask-for-quote__form-container--mobile-services--2">
                                <h2>2. APPLICATION BASICS</h2>
                                <p>2.1. Please rate the importance of the following factors on a scale of 1 to 10.</p>
                                <div className="form-group">
                                    <label htmlFor="speed_of_development">Speed of Development</label>
                                    <p>1. There is no pressing need for the website to go live.</p>
                                    <p>10. Go live ASAP.</p>
                                    <Field name="application_speed_of_development" component="select">
                                        <option>- None -</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                    </Field>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="editing_mobile">Editing</label>
                                    <p>1. Standard content management and website editing features that may require a slight learning curve.</p>
                                    <p>10. Highly customized backend interface that makes content management a breeze</p>
                                    <Field name="application_editing" component="select">
                                        <option>- None -</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                    </Field>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="design_variety_mobile">Design Variety</label>
                                    <p>1. A few different designs for different types of pages.</p>
                                    <p>10. Lots of custom (at least 40% different) page designs.</p>
                                    <Field name="application_design_variety" component="select">
                                        <option>- None -</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                    </Field>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="details_mobile">Details</label>
                                    <p>1. Keep it simple.</p>
                                    <p>10. Get fancy. More complex solutions gadgets and extra elements (e.g. sliders, etc.)</p>
                                    <Field name="application_details" component="select">
                                        <option>- None -</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                    </Field>
                                </div>
                                <div className='form-group'>
                                    <label htmlFor='application_important_action'>2.2. What is the single most important action you’d like visitors to take before they exit the website? If there are other important actions you’d like the visitors of your site to take, please list them here.</label>
                                    <Field name="application_important_action" component="textarea" />
                                </div>
                            </div>
                            <div className="ask-for-quote__form-container--sheet ask-for-quote__form-container--mobile-services--sheet ask-for-quote__form-container--mobile-services--3" id="ask-for-quote__form-container--mobile-services--3">
                                <h2>3. APPLICATION SCREENS & FEATURES</h2>
                                <div className='form-group'>
                                    <label htmlFor='application_features'>3.1. Please list the main sections, screens, and features you’d like to have in your application, including a short description for each (include everything you think is important). If you are using another application as inspiration, please include it.</label>
                                    <Field name="application_features" component="textarea" />
                                    <p>When describing your ideal app, you can also use another app that you like as a blueprint. In that case, list all of the features the blueprint app has that you like. Then, list any sections or features that the blueprint app doesn’t have, but that you like. Feel free to add everything that you have in mind – even if you have additional questions.</p>
                                </div>
                                <div>
                                    <label htmlFor="for_nothing">3.2. Would you like a Native, HTML5, or Hybrid application? *</label>
                                    <div>
                                    <label>
                                        <Field
                                        name="application_type"
                                        component="input"
                                        type="radio"
                                        value="native"
                                        />{' '}
                                        Native
                                    </label>
                                    <label>
                                        <Field
                                        name="application_type"
                                        component="input"
                                        type="radio"
                                        value="html5"
                                        />{' '}
                                        HTML5
                                    </label>
                                    <label>
                                        <Field
                                        name="application_type"
                                        component="input"
                                        type="radio"
                                        value="hybrid"
                                        />{' '}
                                        Hybrid
                                    </label>
                                    <label>
                                        <Field
                                        name="application_type"
                                        component="input"
                                        type="radio"
                                        value="not_sure_yet"
                                        />{' '}
                                        Not sure yet
                                    </label>
                                    </div>
                                    <p>For clarification of application types please read our<Link to="/services/mobile-application-development"> Mobile Application Development page.</Link></p>
                                </div>
                            </div>
                            <div className="ask-for-quote__form-container--sheet ask-for-quote__form-container--mobile-services--sheet ask-for-quote__form-container--mobile-services--4" id="ask-for-quote__form-container--mobile-services--4">
                                <h2>4. CONTENT & IMAGES</h2>
                                <p><span>A new application always requires existing content to be updated and/or new content to be created. During the application development process we will assist you by providing a content guide that shows which texts and images are missing on particular screens. For missing images we’ll give suggestions for available visual solutions, like stock photos. </span></p>
                                <div className='form-group'>
                                    <label htmlFor='mobile_content_images_help'>4.1. We assume that most of the content for the application will be provided, whether from an existing source or newly created, by your team. However, our skilled copy writers are also at your disposal to develop content for the application. Please let us know if you’d like our help with content development.</label>
                                    <Field name="mobile_content_images_help" component="textarea" />
                                </div>
                                <div className='form-group'>
                                    <label htmlFor='mobile_content_images_provide'>4.2. Can you provide materials, like high quality photos and videos related to your products/services, for use in the application? Please describe what kind of material you have available.</label>
                                    <Field name="mobile_content_images_provide" component="textarea" />
                                </div>
                            </div>
                            <div className="ask-for-quote__form-container--sheet ask-for-quote__form-container--web-design--sheet ask-for-quote__form-container--mobile-services--sheet ask-for-quote__form-container--mobile-services--5 ask-for-quote__form-container--web-design--5" id="ask-for-quote__form-container--mobile-services--5">
                                <h2>5. GENERAL INFORMATION</h2>
                                <div className='form-group'>
                                    <Field name='company_name'>
                                        {({ input, meta }) => (
                                            <>
                                                <label htmlFor='company_name'>Company Name</label>
                                                <input {...input} type='text'/>
                                                {meta.error && meta.touched && (
                                                    <span className='form-error-message'>{meta.error}</span>
                                                )}
                                            </>
                                        )}
                                    </Field>
                                </div>
                                <div className='form-group'>
                                    <Field name='website'>
                                        {({ input, meta }) => (
                                            <>
                                                <label htmlFor='website'>Website</label>
                                                <input {...input} type='text'/>
                                                {meta.error && meta.touched && (
                                                    <span className='form-error-message'>{meta.error}</span>
                                                )}
                                            </>
                                        )}
                                    </Field>
                                </div>
                                <div className='form-group'>
                                    <Field name='name'>
                                        {({ input, meta }) => (
                                            <>
                                                <label htmlFor='name'>Name</label>
                                                <input {...input} type='text'/>
                                                {meta.error && meta.touched && (
                                                    <span className='form-error-message'>{meta.error}</span>
                                                )}
                                            </>
                                        )}
                                    </Field>
                                </div>
                                <div className='form-group'>
                                    <Field name='position'>
                                        {({ input, meta }) => (
                                            <>
                                                <label htmlFor='position'>Position</label>
                                                <input {...input} type='text'/>
                                                {meta.error && meta.touched && (
                                                    <span className='form-error-message'>{meta.error}</span>
                                                )}
                                            </>
                                        )}
                                    </Field>
                                </div>
                                <div className='form-group'>
                                    <Field name='email'>
                                        {({ input, meta }) => (
                                            <>
                                                <label htmlFor='email'>Email</label>
                                                <input {...input} type='text'/>
                                                {meta.error && meta.touched && (
                                                    <span className='form-error-message'>{meta.error}</span>
                                                )}
                                            </>
                                        )}
                                    </Field>
                                </div>
                                <div className='form-group'>
                                    <Field name='phone'>
                                        {({ input, meta }) => (
                                            <>
                                                <label htmlFor='phone'>Phone</label>
                                                <input {...input} type='text'/>
                                                {meta.error && meta.touched && (
                                                    <span className='form-error-message'>{meta.error}</span>
                                                )}
                                            </>
                                        )}
                                    </Field>
                                </div>
                                <div className='form-group'>
                                    <Field name='activemedia_auth'>
                                        {({ input, meta }) => (
                                            <>
                                                <label htmlFor='activemedia_auth'>Please put the word activemedia into this field: *</label>
                                                <input {...input} type='text'/>
                                                {meta.error && meta.touched && (
                                                    <span className='form-error-message'>{meta.error}</span>
                                                )}
                                            </>
                                        )}
                                    </Field>
                                </div>
                            </div>
                            </div>
                            <div className="ask-for-quote__form-container--sheet ask-for-quote__form-container--mobile-services--button ask-for-quote__form-container--web-design--button">
                                <HandleButtons count={count} setCount={setCount} submitting={submitting} />
                            </div>
                    </form>
                </div>
            )}
        />
    );
}

const handleSubmit = async values => {
    let base_data = {
        webform_id: 'questionnaire',
        in_draft: false
    };
    const data = {
        ...base_data,
        ...values
    };
    //console.log(data);

    const response = await axios.post(
        'https://backend.activemedia.com/react_webform_backend/submit',
        {
            ...data
        }
    );
    if (response.data.error) {
        //console.log("hahaa");
        //throw new WebformError(response);
    } else {
        navigate("/thank-you");
    }
    //console.log(response);
    //console.log('Submitted');
}

export default AskForQuoteWebDesign;