import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
//import ReactPlayer from 'react-player';
import { getFile } from '../helpers/fileFinder';
import { GatsbyImage } from "gatsby-plugin-image"

const DrupalFile = (context,{ node, id = null, image_style = 'full' }) => {
     
    return (
        <StaticQuery
        query={graphql`
            query allFileQuery {
                drupal {
                    mediaQuery(
                        limit: 999999
                        filter: { conditions: { field: "status", value: "1", operator: EQUAL } }
                    ) {
                        entities {
                            entityId
                            entityBundle
                            entityUuid
                            ... on Drupal_MediaImage {
                                mid
                                uuid
                                gatsbyImageFile {
                                    id
                                    publicURL
                                    extension
                                    childImageSharp {
                                        gatsbyImageData
                                    }
                                }
                                fieldMediaImage {
                                    url
                                    alt
                                }
                            }
                            ... on Drupal_MediaSlideshow {
                                mid
                                uuid
                                
                                fieldMediaImage1 {
                                    url
                                    alt
                                }
                            }
                        }
                    }
                }   
            }
        `}
        render={data => (
            <FileRenderer  data={data}  {...context}/>
        )}
        />
    ) 
};
const FileRenderer = ({data, node, id, image_style }  ) => {
    
    let image_id = null;

    if (id == null || typeof(id) == "undefined") {
        image_id = node?.id;
    }

    if (image_id == null) {
       image_id = id; 
    }
  
    image_style = 'full';


    let file = getFile(image_id, data);
    let alt;
    let figure_caption;
    if (typeof file != 'undefined' && file != null) {
        //console.log(file)
        switch (String(file.entityBundle)) {
            default:
            case 'Drupal_File':
                const dimage = file.notStyledImage;
          
                if (dimage?.extension) {
                            if (dimage.extension === 'svg') {
                                return <img alt={alt} src={dimage.publicURL} />;
                            }
                        }
          
                        if (dimage?.childImageSharp) {
                            return (
                                <figure>
                                    {/*<Img
                                        alt={alt}
                                        fluid={dimage.childImageSharp.fluid}
                                    />*/}
                                    <GatsbyImage alt={alt} image={dimage.childImageSharp.gatsbyImageData} layout="fullWidth"
                            placeholder="blurred"/>
                                    {figure_caption}
                                </figure>
                            );
                        }
                break;
            case 'slideshow' :

                alt = file.fieldMediaSlideshow?.alt;

                const slimimage = file.fieldMediaImage1.url;
                //console.log(slimimage)

                if (file.fieldMediaImage1) {
                    return (
                        <img alt={alt} src={slimimage} />
                    )
                }
                else {
                    <>Error</>                    
                }
            case 'image':

                alt = file.fieldMediaImage?.alt;

                const image = file.gatsbyImageFile;
 
                if (file.fieldImageCaption) {
                    figure_caption = <figcaption>{file.fieldImageCaption}</figcaption>;
                }
                switch (image_style) {
                    case 'full':
                        if (image.extension) {
                            if (image.extension === 'svg') {
                                return <img alt={alt} src={image.publicURL} />;
                            }
                        }
          
                        if (image.childImageSharp) {
                            return (
                                <figure>
                                    {/*<Img
                                        alt={alt}
                                        fluid={image.childImageSharp.fluid}
                                    />*/}
                                    <GatsbyImage alt={alt} image={image.childImageSharp.gatsbyImageData} layout="fullWidth"
                            placeholder="blurred"/>
                                    {figure_caption}
                                </figure>
                            );
                        }
                        else {

                            return (
                                <>No childImageSharp</>
                            );
                        }

                    default:
                        //const image_styled = file[image_style + '_style'];

                        if (image.extension === 'svg') {
                            return <img alt={file.fieldMediaImage.alt} src={image.publicURL} />;
                        }

                        if (image.childImageSharp) {
                            return (
                                <figure>
                                    {/*<Img
                                        alt={alt}
                                        fluid={image.childImageSharp.fluid}
                                    />*/}
                                    <GatsbyImage alt={alt} image={image.childImageSharp.gatsbyImageData} layout="fullWidth"
                            placeholder="blurred"/>
                                    {figure_caption}
                                </figure>
                            );
                        }
                        else {

                            return (
                                <>No childImageSharp</>
                            );
                        }
                  
                }
                
            }
        
    }
    
    else {
        return <>File not found(Maybe not downloaded, from source): {image_id}</>
    }

    return <>Valam nem vart hiba tortent</>
}
export default DrupalFile;
